<template>
	<div class="allorder">
		<div class="all-order-wrap">
			<ul class="allorder">
				<li @click="GetOrder1(0)" :class="{ all: cur == '0' }">全部</li>
				<li @click="GetOrder1(1)" :class="{ all: cur == '1' }" v-if="false">待付款</li>
				<li @click="GetOrder1(3)" :class="{ all: cur == '3' }">待使用</li>
				<li @click="GetOrder1(4)" :class="{ all: cur == '4' }">已完成</li>
			</ul>
		</div>
		<div class="tabbar-wrap" v-if="isLoad">
			<div class="tabbar" v-if="allorder.length > 0">
				<!-- //全部订单 -->
				<div class="tabbar-all" v-for="(item, index) in allorder" :key="index">
					<div class="tabbar-all-top">
						<div class="tabbar-all-top-l">
							<p>单号:</p>
							<p>{{ item.order_no }}</p>
						</div>
						<div class="tabbar-all-top-r">
							<p v-if="item.order_status == '1'">待付款</p>
							<p v-else-if="item.order_status == '3'">待使用</p>
							<p v-else-if="item.order_status == '4'">已完成</p>
							<p v-else-if="item.order_status == '0'">已取消</p>
							<p v-else>未知</p>
						</div>
					</div>
					<div class="tabbar-all-center">
						<img :src="item.goods_image" alt />
						<div class="tabbar-all-c-r">
							<p>{{ item.goods_name }}</p>
							<p class="p6">{{ item.specs_item_value }}</p>
							<div class="tabbar-all-c-r-b">
								<p>￥{{ item.goods_price }}</p>
								<p>x{{ item.goods_nums }}</p>
							</div>
						</div>
					</div>
					<div class="tabbar-all-bottom">
						<div class="left">使用有效期至{{ item.expire_date}}</div>
						<div class="right">
							<p>总价:</p>
							<p>￥</p>
							<p>{{ item.order_amount }}</p>
						</div>
					</div>
					<div class="tabbar-all-order">
						<p @click="CloseOrder(item.order_id)" v-if="item.order_status == 1">取消订单</p>
						<p @click="deleteOrder(item.order_id)"
							v-else-if="item.order_status == 0 || item.order_status == 4">删除订单</p>
						<router-link :to="'/nouse?id=' + item.order_id" tag="p">查看订单</router-link>
					</div>
				</div>
				<load-more :loadLastText="loadLastText" @getMore="getMore"></load-more>
			</div>
			<no-data v-else content="没有相关订单"></no-data>
		</div>
	</div>
</template>

<script>
	import {
		AllCardsOrder,
		closeCardsOrder,
		deleteCardsOrder
	} from "../../http/api";
	export default {
		data() {
			return {
				userToken: "",
				cur: 0,
				select: 0,
				pages: 1,
				loadLastText: true,
				isLoad: false,
				allorder: []
			};
		},
		mounted() {
			this.userToken = this.$LStorage.getItem("userToken").user_token;
			if (this.$route.query.cur) {
				this.cur = this.$route.query.cur
			}
			this.GetOrder();
		},
		methods: {
			GetOrder1(cur) {
				if (this.cur != cur) {
					this.cur = cur;
					this.allorder = [];
					this.pages = 1;
					this.isLoad = false;
					this.loadLastText = true;
					this.GetOrder();
				}
			},

			//获取订单
			async GetOrder() {
				const res = await AllCardsOrder({
					data: {
						user_token: this.userToken,
						pages: this.pages,
						order_status: this.cur
					}
				});
				this.isLoad = true;
				console.log(res);
				if (res.code == "200") {
					this.isLoad = true;
					this.allorder = this.allorder.concat(res.data);
					if (res.data.length < res.numPage) {
						this.loadLastText = false;
					} else {
						this.pages++;
						this.loadLastText = true;
					}
				}
			},

			//点击查看更多
			getMore() {
				this.GetOrder();
			},

			//取消订单
			async CloseOrder(order_id) {
				const res = await closeCardsOrder({
					data: {
						order_id: order_id,
						user_token: this.userToken
					}
				});
				// console.log(res);
				if (res.code == 200) {
					this.$toast.success("取消成功");
					this.pages = 1;
					this.isLoad = false;
					this.loadLastText = true;
					this.allorder = [];
					this.GetOrder();
				} else {
					this.$toast.fail("取消失败");
				}
			},

			async deleteOrder(order_id) {
				this.$dialog.confirm({
					title: "提示",
					message: "确认要删除这个订单吗？"
				}).then(() => {
					// on confirm
					deleteCardsOrder({
						data: {
							user_token: this.userToken,
							order_id: order_id
						}
					}).then(res => {
						if (res.code == 200) {
							this.$toast.success("删除成功");
							this.pages = 1;
							this.isLoad = false;
							this.loadLastText = true;
							this.allorder = [];
							this.GetOrder();
						} else {
							this.$toast.fail("删除失败");
						}
					});
				});
			}
		},
		created() {}
	};
</script>

<style lang="scss">
	.allorder {
		width: 100%;
		height: 100vh;
		display: flex;
		flex-direction: column;

		.all-order-wrap {
			width: 100%;
			height: 1.2rem;

			// overflow: scroll;
			.allorder {
				width: 100%;
				height: 100%;
				// overflow: scroll;
				display: flex;
				flex-direction: row;
				justify-content: space-around;
				border-bottom: 1px solid #e8e8e8;

				li {
					width: 1.5rem;
					font-size: 0.28rem;
					line-height: 1.5rem;
					height: 1.18rem;
					color: #b0b0b0;
					text-align: center;
				}

				.all {
					color: #e40011;
					border-bottom: 1px solid #e40011;
				}
			}
		}

		.tabbar-wrap {
			width: 100%;
			height: calc(100% - 1.2rem);
			overflow-y: auto;
			background: #f6f6f6;
		}

		.tabbar {
			width: 100%;
			min-height: 100%;
			padding: 0.37rem 0.27rem 0.79rem 0.25rem;
			box-sizing: border-box;

			.tabbar-all {
				// width: 100%;
				background: #fff;
				margin-bottom: 0.3rem;
				border-radius: 0.1rem;
				padding: 0 0.1rem;

				.tabbar-all-top {
					height: 0.82rem;
					display: flex;
					align-items: center;
					justify-content: space-between;
					border-bottom: 0.01rem solid #eeeeee;
					font-size: 0.26rem;

					.tabbar-all-top-l {
						display: flex;
						align-items: center;
					}

					.tabbar-all-top-r {
						color: #e50012;
					}
				}

				.tabbar-all-center {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 0.18rem 0;
					border-bottom: 0.01rem solid #eeeeee;

					img {
						width: 2.46rem;
						height: 1.79rem;
						object-fit: cover;
					}

					.tabbar-all-c-r {
						width: calc(100% - 2.66rem);
						height: 1.79rem;
						display: flex;
						flex-direction: column;
						justify-content: space-between;

						p:nth-child(1) {
							font-size: 0.28rem;
							text-overflow: ellipsis;
							white-space: nowrap;
							overflow: hidden;
						}

						.p6 {
							font-size: 0.26rem;
							color: #999;
						}

						.tabbar-all-c-r-b {
							display: flex;
							justify-content: space-between;
							align-items: center;
							font-size: 0.24rem;

							p:nth-child(1) {
								color: #eb676c;
							}

							p:nth-child(2) {
								color: #888888;
							}
						}
					}
				}

				.tabbar-all-bottom {
					height: 0.97rem;
					line-height: 0.97rem;
					border-bottom: 0.01rem solid #eeeeee;
					display: flex;
					justify-content: space-between;
					align-items: center;
					font-size: 0.24rem;

					.right {
						display: flex;

						p:nth-child(1) {
							font-size: 0.22rem;
							color: #666666;
						}

						p:nth-child(2) {
							font-size: 0.19rem;
							color: #e46d6d;
						}

						p:nth-child(3) {
							font-size: 0.23rem;
							color: #e46d6d;
						}
					}
				}

				.tabbar-all-order {
					height: 1.15rem;
					display: flex;
					align-items: center;
					justify-content: flex-end;

					p {
						font-size: 0.25rem;
						width: 1.37rem;
						height: 0.55rem;
						border-radius: 0.08rem;
						border: 1px solid #8d8d8d;
						text-align: center;
						line-height: 0.55rem;
						color: #585858;
						margin-right: 0.33rem;
					}

					p:last-child {
						margin-right: 0;
					}

					.fukuan {
						border: 1px solid #e40011;
						color: #e40011;
					}
				}
			}
		}
	}
</style>
